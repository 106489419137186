@font-face {
  font-family: "CarterOne";
  src: url("../public/fonts/CarterOne-Regular.ttf") format("truetype");
}
$gradient: linear-gradient(to bottom, #085038 0%, #0d653f 100%);

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.app-header {
  background: $gradient;
  display: flex;
  color: white;
  padding: 3em 0 3em 0;
  h1 {
    font-family: "CarterOne";
    font-size: 40px;
    text-justify: distribute;
  }
}

.app-body {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background-color: #f0f7ed;
  h2 {
    color: #808080;
  }
}

.grid-container {
  min-height: 600px;
  width: 100%;
}

.store-images {
  height: 54px;
  width: 179px;
}

.app-footer {
  background-color: #f0f7ed;
  display: flex;
  padding: 2em 0 2em 0;
}

.email-form {
  label {
    margin-bottom: 0.2em;
    margin-top: 0.5em;
  }
  input,
  textarea {
    margin-top: 0.5em;
    padding: 0.2em;
  }
}
